


















import { Vue, Component, Watch } from 'vue-property-decorator';
import Layout from '@/layouts/LayoutDefault.vue';
import Step1 from '@modules/professional-risk/components/form/ProActionPlanStep1Form.vue';
import Step2 from '@modules/professional-risk/components/form/ProActionPlanStep2Form.vue';

import ActionPlan from '@models/proRisk/ActionPlan';
import { ActionInterface } from '@/types/breadcrumb';
import { namespace } from 'vuex-class';
import { VForm } from '@/types/v-form';
import ProFolder from '@models/proRisk/ProFolder';
import ActionPlanForm from '@modules/professional-risk/form/ActionPlanForm';
import RequestSaveForm from '@/utils/RequestSaveForm';

const actionPlanRepository = namespace('repositories/proActionPlan');
const riskProModule = namespace('riskPro');

@Component({
  components: {
    Layout,
    Step1,
    Step2,
  },
})
export default class ActionPlanFormView extends Vue {
  actionPlan: ActionPlan | any = null;
  actionPlanForm: ActionPlanForm = new ActionPlanForm(new ActionPlan());

  @riskProModule.Getter('getActiveProFolder')
  getActiveProFolder!: ProFolder | null;

  @actionPlanRepository.Action('insertOrUpdate')
  save!: (actionPlan: ActionPlanForm | null) => Promise<void>;

  loading: boolean = false;

  $refs!: {
    form: VForm,
    child: any
  };

  get rules() {
    return {};
  }

  get currentStep() {
    return this.actionPlanForm.riskId !== null ? 'Step2' : 'Step1';
  }

  get actionsCurrentStep() {
    return this.currentStep === 'Step1' ? this.actionsStep1 : this.actionsStep2;
  }

  get actionsStep1(): ActionInterface[] {
    return [];
  }

  get actionsStep2(): ActionInterface[] {
    const actions: ActionInterface[] = [];
    if (!this.actionPlan.id) {
      actions.push({
        label: 'Revenir à la sélection',
        handler: () => {
          this.actionPlanForm.riskId = null;
        },
      });
    }

    actions.push(
      {
        label: 'Enregistrer et ajouter',
        handler: () => {
          this.loading = true;
          this.validate()
            .then(() => {
              this.actionPlanForm.riskId = null;
              this.$router.push({ name: 'pro-risks-action-plan-create' });
            })
            .finally(() => {
              this.loading = false;
            });
        },
        condition: () => !this.loading,
      },
      {
        label: 'Enregistrer et revenir',
        handler: () => {
          this.loading = true;
          this.validate()
            .then(() => {
              this.$router.push({ name: 'pro-risks-action-plan' });
            })
            .finally(() => {
              this.loading = false;
            });
        },
        condition: () => !this.loading,
      },
    );

    return actions;
  }

  @Watch('$route.params.id', { immediate: true })
  onRouteChange(id: number | null | undefined) {
    this.actionPlan = id !== null && id !== undefined
      ? ActionPlan.query().with(['structures', 'folder']).find(id) as ActionPlan
      : this.createNewActionPlan();

    this.actionPlanForm = new ActionPlanForm(this.actionPlan);
  }

  createNewActionPlan() {
    const actionPlan = new ActionPlan();
    const folder = this.getActiveProFolder;
    if (folder !== null) {
      actionPlan.folder = folder;
      actionPlan.folderId = folder.id;
    }

    return actionPlan;
  }

  async validate() {
    this.loading = true;
    let validForm = false;
    let errorFields = {};

    this.$refs.child.getForm().validate((valid: boolean, errors: {}) => {
      validForm = valid;
      errorFields = { ...errors };
    });

    this.$refs.form.validate((valid, errors) => {
      validForm = validForm && valid;
      errorFields = { ...errors };
    });

    if (!validForm) {
      this.loading = false;
      throw new Error('Validation failed');
    }

    return this.handleSave().finally(() => {
      this.loading = false;
      return true;
    });
  }

  @RequestSaveForm({
    validMsg: 'Sauvegarde effectuée.',
    errorMsg: 'Erreur lors de la sauvegarde de votre plan d\'action.',
  })
  handleSave() {
    return this.save(this.actionPlanForm)
      .then(() => {
        this.handleRedirect('pro-risks-action-plan');
        this.$emit('valid');
      });
  }
}
