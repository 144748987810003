import ActionPlan from '@models/proRisk/ActionPlan';
import { Expose, Transform } from 'class-transformer';
import moment from 'moment';

export default class ActionPlanForm {
  constructor(actionPlan: ActionPlan) {
    this.id = actionPlan.id;
    this.comment = actionPlan.comment;
    this.driver = actionPlan.driver;
    this.resources = actionPlan.resources;
    this.aims = actionPlan.aims;
    this.abandoned = actionPlan.abandoned;
    this.fixedDate = actionPlan.fixedDate;
    this.closedDate = actionPlan.closedDate;
    this.riskId = actionPlan.riskId;
    this.folderId = actionPlan.folderId;
    this.structures = actionPlan.structures.map(structure => structure.id);
  }

  @Expose({ groups: ['put'] })
  id!: number | null;

  @Expose({ groups: ['post', 'put'] })
  comment!: string | null;

  @Expose({ groups: ['post', 'put'] })
  driver!: string | null;

  @Expose({ groups: ['post', 'put'] })
  resources!: string | null;

  @Expose({ groups: ['post', 'put'] })
  aims!: string | null;

  @Expose({ groups: ['post', 'put'] })
  abandoned!: boolean;

  @Expose({ groups: ['post', 'put'] })
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  fixedDate!: Date | null;

  @Expose({ groups: ['post', 'put'] })
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  closedDate!: Date | null;

  @Expose({ name: 'folder', groups: ['post'] })
  folderId!: number | null;

  @Expose({ name: 'risk', groups: ['post'] })
  riskId!: number | null;

  @Expose({ groups: ['post', 'put'] })
  structures!: Array<number | null>;
}
