






















































































































































import _ from 'lodash';
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';

import FirmStructureTree from '@/components/utils/FirmStructureTree.vue';
import { namespace } from 'vuex-class';
import Risk from '@models/proRisk/Risk';
import Thesaurus from '@models/proRisk/Thesaurus';
import RiskCharacter from '@models/proRisk/RiskCharacter';
import FirmStructure from '@models/firm/FirmStructure';
import { lazyInject } from '@/ioc/container';
import ActionPlanForm from '@modules/professional-risk/form/ActionPlanForm';
import SERVICE_IDENTIFIER from '@modules/professional-risk/ioc/identifiers';
import { RiskServiceInterface } from '@modules/professional-risk/services/RiskServiceInterface';
import { Criticality, CRITICALITY_UNDEFINED } from '@modules/professional-risk/types/risk';
import * as Rules from '@/utils/CustomFormRules';
import {
  FrequenciesEnumName,
  MastersEnumName,
  SeriousnessEnumName,
} from '@modules/professional-risk/types/enum';
import { VForm } from '@/types/v-form';

const firmModule = namespace('firm');
const thesaurusRepository = namespace('repositories/thesaurus');
const riskCharacterRepository = namespace('repositories/riskCharacter');

@Component({
  components: { FirmStructureTree },
})
export default class ProActionPlanStep2Form extends Vue {
  @Prop({ type: Object })
  value!: ActionPlanForm;

  @lazyInject(SERVICE_IDENTIFIER.RiskServiceInterface)
  riskService!: RiskServiceInterface;

  @firmModule.Getter('getOrganization')
  structures!: FirmStructure[];

  @thesaurusRepository.Action('findByCharacter')
  fetchThesaurus!: (characterId: number) => Promise<void>;

  @thesaurusRepository.Getter('getByCharacter')
  getThesaurusByCharacter!: (characterId: number) => Thesaurus[];

  @riskCharacterRepository.Getter('get')
  getRiskCharacter!: (id: number) => RiskCharacter | null;

  @riskCharacterRepository.Action('find')
  fetchRiskCharacter!: (id: number) => Promise<void>;

  buffer: ActionPlanForm | null = null;

  frequencies = FrequenciesEnumName;
  seriousness = SeriousnessEnumName;
  masters = MastersEnumName;

  $refs!: {
    formStep2: VForm,
  };

  get rules() {
    return {
      // structures: [
      //   {
      //     message: 'Veuillez renseigner une affectation',
      //     trigger: 'blur',
      //     validator: Rules.validateArray,
      //   },
      // ],
    };
  }

  get risk() {
    return this.value.riskId ? Risk.query().with(['structures']).find(this.value.riskId) : null;
  }

  get criticality(): Criticality {
    return this.risk ? this.riskService.criticality(this.risk) : CRITICALITY_UNDEFINED;
  }

  get riskCharacterLabel() {
    const riskCharacter = this.risk && this.risk.characterId ? this.getRiskCharacter(this.risk.characterId) : null;
    return riskCharacter ? riskCharacter.label : '';
  }

  get riskTitle() {
    return `${this.riskCharacterLabel} (Criticité ${this.criticality.quotation} : ${this.criticality.value})`;
  }

  get thesauruses() {
    return this.risk && this.risk.characterId ? this.getThesaurusByCharacter(this.risk.characterId) : [];
  }

  get dataStructures() {
    return this.risk && this.risk.structures ? this.risk.structures.map(e => e.id) : [];
  }

  @Watch('risk', { immediate: true })
  onRiskChange(value: Risk | null) {
    if (value && value.characterId) {
      this.fetchRiskCharacter(value.characterId);
      this.fetchThesaurus(value.characterId);
    }
  }

  @Watch('value', { immediate: true, deep: true })
  onValueChange(value: ActionPlanForm | null) {
    if (!_.isEqual(this.buffer, value)) {
      this.buffer = _.cloneDeep(value);
    }
  }

  @Watch('buffer', { immediate: true })
  onBufferChange(value: ActionPlanForm | null) {
    this.$emit('input', this.buffer);
  }

  getForm() {
    return this.$refs.formStep2;
  }

  onSelectThesaurus(thesaurusId: number | null) {
    if (thesaurusId) {
      const thesaurus = this.thesauruses.find(t => t.id === thesaurusId) || null;
      if (this.buffer && thesaurus) {
        this.buffer.aims = this.buffer.aims ? `${this.buffer.aims}
${thesaurus.content}` : thesaurus.content;
      }
    }
  }
}
