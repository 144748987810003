






























import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { lazyInject } from '@/ioc/container';
import ProRiskQuotationFilter
  from '@modules/professional-risk/components/form/ProRiskQuotationFilter.vue';

import Risk from '@models/proRisk/Risk';
import ProRiskQuotationCard from '@modules/professional-risk/components/ProRiskQuotationCard.vue';
import ProFolder from '@models/proRisk/ProFolder';
import ActionPlanForm from '@modules/professional-risk/form/ActionPlanForm';
import FirmStructure from '@models/firm/FirmStructure';

import { RiskServiceInterface } from '@modules/professional-risk/services/RiskServiceInterface';
import SERVICE_IDENTIFIER from '@modules/professional-risk/ioc/identifiers';
import { PaginatedData } from '@/types/paginatedData';

const riskProModule = namespace('riskPro');
const riskProRepository = namespace('repositories/risk');

@Component({
  components: { ProRiskQuotationCard, ProRiskQuotationFilter },
})
export default class ProActionPlanStep1Form extends Vue {
  @lazyInject(SERVICE_IDENTIFIER.RiskServiceInterface)
  riskService!: RiskServiceInterface;

  @Prop({ type: Object })
  value!: ActionPlanForm;

  @riskProModule.Getter('getActiveProFolder')
  proFolder!: ProFolder | null;

  @riskProRepository.Getter('getAll')
  risks!: Risk[];

  @riskProRepository.Action('search')
  fetchRisks!: (payload: { folder: ProFolder, filters: any }) => Promise<PaginatedData<Risk>>;

  colors: Record<number, string> = {};
  totalResult = 0;

  filters: any = {
    riskCharacter: '',
    criticality: '',
    job: '',
    unit: '',
    limit: 10,
    page: 1,
  };

  loading: boolean = false;

  @Watch('filters', { immediate: true, deep: true })
  async onFilterChange() {
    if (this.proFolder && !this.loading) {
      this.loading = true;
      const data = await this.fetchRisks({ folder: this.proFolder, filters: this.filters });
      this.totalResult = data.meta.total;
      this.loading = false;
    }
  }

  valid(risk: Risk) {
    this.value.riskId = risk ? risk.id : null;
    this.value.structures = risk.structures.map((v: FirmStructure) => v.id);
  }

  color(risk: Risk) {
    return this.riskService.color(risk);
  }
}
